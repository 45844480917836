<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"/>

    <edit-field
      :fields="fields"
      :row-data="row_data"
      post-api="/staffmanage/AddStaff"
      finish-router="staff"
      permission="Sta_Create"
      :submit-action="submitAction"
    >
      <template #facility="scope">
        <SlotFacilityRoleIDs
          :company-id="scope.rowData.companyID"
          :row-data="scope.rowData"
          @onRoleListChange="onRoleListChange"/>
      </template>

      <template v-slot:facilityNfcs="scope">
        <field-inputs
          :is-edit="scope.isEdit"
          :root-item="scope.rootItem"
          :items="[]"
          :show-add-btn="false"
          :show-delete-btn="false"/>
      </template>

      <template v-slot:callBellNfcs="scope">
        <field-modal-call-bell-nfcs
          :is-edit="scope.isEdit"
          :row-data="scope.rowData"
          :root-item="scope.rootItem"
          :items="row_data.callBellNfcs"
          :facilityAndRoleList="facilityAndRoleList"
          :show-add-btn="true"
          :show-delete-btn="true"
          @updateValues="onCallBellNfcsUpdate"
        />
      </template>

    </edit-field>
  </page-content>
</template>

<script>
import fields, { reloadFieldData } from "@/views/staff/fields";
import EditField from "@/components/EditField2";
import EditFieldHeader from "@/components/EditFieldHeader";
import PageContent from "@/components/PageContent";
import SlotFacilityRoleIDs from "@/views/staff/SlotFacilityRoleIDs";
import common from "@/common";
import FieldInputs from '../../components/FieldInputs';
import FieldModalCallBellNfcs from './FieldModalCallBellNfcs.vue'
import _ from 'lodash'

export default {
  name: "Add",
  components: {
    SlotFacilityRoleIDs,
    PageContent,
    EditFieldHeader,
    EditField,
    fields,
    FieldInputs,
    FieldModalCallBellNfcs,
  },
  data() {
    return {
      title: common.getMenuName('staff') + ' - ' + common.getI18n('common.add'),
      fields,
      facilityAndRoleList: [],

      // 如果是checkbox，里面写 field: [], 如ids: []
      row_data: {
        isActive: true,
        companyID: common.getCompanyId()
      }
    }
  },
  created() {
    reloadFieldData()
  },
  methods: {
    onRoleListChange(facilityAndRoleList) {
      this.facilityAndRoleList = facilityAndRoleList
    },
    onCallBellNfcsUpdate(nfcs) {
      this.row_data.callBellNfcs = nfcs
    },
    submitAction(rowData, api, finishRouter) {
      const that = this
      return submit()

      function submit() {
        that.$requestWehealth({ method: 'post', url: api, data: rowData })
          .then(() => that.$router.push({ name: finishRouter }))
          .catch((err) => {
            const res = err.cause?.respond
            if (
              res?.result_msg === "CallBellNfcIsUsing"
              && res?.data?.staff
              && res?.data?.nfcSerialNumber
            ) {
              common.showAlert({
                title: common.getI18n('error.CallBellNfcIsUsing'),
                text: `NFC: ${res?.data?.nfcSerialNumber}`
                  + ` | ${common.getI18n('common.staff')}: ${res?.data?.staff.chineseName}`,
              })
            }
          })
      }
    },
  },
}
</script>

<style scoped>

</style>
